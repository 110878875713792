import axios from 'axios';
import React, { useState } from 'react'
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

const PlanModal = ({ closeModal }) => {
  const [inputs, setInputs] = useState([{ plan: "" }]);
  const [name, setDays] = useState('');
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState('');

  const handleAddInput = () => {
    setInputs([...inputs, { plan: "" }]);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const createPlan = (ev) => {
    ev.preventDefault();

    const data = new FormData();

    data.append('price', price);
    data.append('name', name);
    data.append('file', file);
    // data.append('file2', file2);
    // data.append('content', content);
    // data.append('plans', personName);
    // const formattedPlans = personName.map(plan => ({ price: plan }));
    // data.append('plans', JSON.stringify(formattedPlans));

    // const data = {
    //   plans: inputs,
    //   price: parseFloat(price), // Convert to number
    //   days: parseInt(days), // Convert to number
    //   docx: file
    // };
    // data.append('plans', inputs);

    axios.post('https://greencamel.zyberapi.site/plan', data)
      .then(() => window.location.reload(false))
  }

  return (
    <div className='blur_container'>
      <div className='modal'>
        <div className="two-line">
          <h2>Add Plan</h2>
          <h2 onClick={closeModal}>X</h2>
        </div>
        <label htmlFor="">
          Price:
          <input onChange={(e) => setPrice(e.target.value)} type="number" name="" id="" />
        </label>
        <label htmlFor="">
          Name:
          <input onChange={(e) => setDays(e.target.value)} type="text" name="" id="" />
        </label>
        <label htmlFor="">
          file:
          <input onChange={(e) => setFile(e.target.files[0])} type="file" name="" id="" />
        </label>
        {/* {inputs.map((item, index) => (
          <div className="input_container" key={index}>
            <label htmlFor="">
              Plan:
              <input
                name="plan"
                placeholder='Plan'
                type="text"
                value={item.plan}
                onChange={(event) => handleChange(event, index)}
              />
            </label>
            {inputs.length > 1 && (
              //   <button onClick={() => handleDeleteInput(index)}>Delete</button>
              <label htmlFor="">
                <br />
                <MdDeleteForever size={40} color='red' onClick={() => handleDeleteInput(index)} />
              </label>
            )}
            {index === inputs.length - 1 && (
              //   <button onClick={() => handleAddInput()}>Add</button>
              <label htmlFor="">
                <br />
                <IoMdAddCircleOutline color='green' size={40} onClick={() => handleAddInput()} />
              </label>
            )}
          </div>
        ))} */}
        <div className="two-line">
          <button onClick={(e) => createPlan(e)} className='button'>Save</button>
          <button onClick={closeModal} className='cancel-button'>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default PlanModal
