import React, { useEffect, useState } from 'react';
import CommentChart from '../../Chart/CommentChart';
import axios from 'axios';

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [comment, setComment] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [chart, setChart] = useState([]);

    useEffect(() => {
        if (isLoading) {
            Promise.all([
                axios.get('https://greencamel.zyberapi.site/feedback'),
                axios.get('https://greencamel.zyberapi.site/user')
            ]).then(([feedbackResponse, userResponse]) => {
                setComment(feedbackResponse.data.data);
                setUsers(userResponse.data.data);
                setIsLoading(false);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (!isLoading) {
            const chartData = users.map(user => ({
                user: user.name,
                commentlength: comment.filter((e) => e.user === user._id).length,
                experience: comment.filter((e) => e.user === user._id && e.experience === true).length,
                skill: comment.filter((e) => e.user === user._id && e.skill === true).length,
                knowledge: comment.filter((e) => e.user === user._id && e.knowledge === true).length,
                communication: comment.filter((e) => e.user === user._id && e.communication === true).length,
                reliability: comment.filter((e) => e.user === user._id && e.reliability === true).length,
            }));
            // console.log('++=' + chartData);
            setChart(chartData);
        }
    }, [isLoading, users, comment]);

    return (
        <div className='container'>
            {!isLoading && chart.length > 0 && <CommentChart comment={chart} />}
        </div>
    );
}

export default Dashboard;
