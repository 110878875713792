import React, { useEffect, useState } from 'react'
import styles from './Lands.module.css'
import AddLandsModal from '../../Modal/LandsModal/AddLandsModal'
import axios from 'axios';
import { FaPen } from "react-icons/fa";
import EditLandsModal from '../../Modal/LandsModal/EditLandsModal';

const Lands = () => {
  const [isModal, setIsModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lands, setLands] = useState([]);
  const [plans, setPlans] = useState([]);
  const [editId, setEditId] = useState('');
  const [planJson, setPlanJson] = useState({});

  const closeModal = () => {
    setIsModal(false);
    setIsEditModal(false);
  }

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      axios.get('https://greencamel.zyberapi.site/category')
        .then((e) => setLands(e.data.data))

      axios.get('https://greencamel.zyberapi.site/plan')
        .then((e) => setPlans(e.data.data))
    }
    document.title = 'Газрууд';
  }, [isLoading])

  return (
    <>
      {isEditModal && planJson && editId ? <EditLandsModal editId={editId} plans={plans} land={planJson} closeModal={closeModal} /> : <></>}
      {isModal ? <AddLandsModal plans={plans} closeModal={closeModal} /> : <></>}
      <div className='container'>
        <button onClick={() => setIsModal(true)} className='button'>Add Land</button>
        <div className='grid-4'>
          {lands.map((e) => (
            <div className={styles.card}>
              <img src={'https://greencamel.zyberapi.site/uploads/' + e.cover} alt="" />
              <p>{e.title}</p>
              <div style={{ width: 'max-content', padding: '10px' }} className="two-line">
                <FaPen size={20} color='blue' onClick={() => {
                  setEditId(e._id);
                  setIsEditModal(true);
                  setPlanJson(e);
                }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Lands
