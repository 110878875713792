import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
    yAxis: [
        {
            label: 'Сэтгэгдэл болон үнэлгээ',
        },
    ],
    width: 1000,
    height: 550,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-10px, 0)',
            // margin: '10px',
        },
    },
};

const valueFormatter = value => `${value} ширхэг`;

const CommentChart = ({ comment }) => {
    // console.log('====' + comment);
    return (
        <BarChart
            dataset={comment}
            xAxis={[{ scaleType: 'band', dataKey: 'user' }]}
            series={[
                { dataKey: 'commentlength', label: 'Сэтгэгдэлийн тоо', valueFormatter },
                { dataKey: 'experience', label: 'Туршлага', valueFormatter },
                { dataKey: 'skill', label: 'Ур чадвар', valueFormatter },
                { dataKey: 'knowledge', label: 'Мэдлэг', valueFormatter },
                { dataKey: 'communication', label: 'Харилцааны чадвар', valueFormatter },
                { dataKey: 'reliability', label: 'Найдвартай байдал', valueFormatter }
            ]}
            {...chartSetting}
        />
    );
};

export default CommentChart;
