import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function PlanTable({ plans }) {
    return (
        <TableContainer style={{ width: '80vw' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='left'>No:</StyledTableCell>
                        <StyledTableCell align="center">Нэр</StyledTableCell>
                        <StyledTableCell align="center">Үнэ / $ /</StyledTableCell>
                        <StyledTableCell align="center">Файл</StyledTableCell>
                        {/* <StyledTableCell align="center">Protein&nbsp;(g)</StyledTableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plans.map((row, index) => (
                        <StyledTableRow className='row-item' key={row._id}>
                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                            <StyledTableCell align='center' component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.price}$</StyledTableCell>
                            <StyledTableCell align="center">
                                <a href={'https://greencamel.zyberapi.site/uploads/' + row.docx}>
                                    Download Plan
                                </a>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}