import React, { useEffect, useState } from 'react'
import { IoWarningOutline } from "react-icons/io5";
import { FaBan } from "react-icons/fa";
import styles from './Users.module.css'
import axios from 'axios';
import UsersTable from '../../Tables/UserTable';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState('');

  useEffect(() => {
    if (isLoading) {
      axios.get('https://greencamel.zyberapi.site/user')
        .then((e) => setUsers(e.data.data))
        .catch((e) => console.log(e));
      setIsLoading(false);
    }

    document.title = 'Хэрэглэгчид';
  })

  const banUser = ({ banUser }) => {
    axios.put('https:/greencamel.zyberapi.site/user/' + banUser, {
      isBan: true
    })
      .then(() => window.location.reload(false));
  }


  return (
    <div className='container'>
      <h2>Хэрэглэгчид:</h2>
      <div className="two-line">
        <label>
          Төрлөөр шүүх:
          <select onChange={(e) => setRole(e.target.value)}>
            <option value="">Бүгд</option>
            <option value="driver">Жолооч</option>
            <option value="guide">Хөтөч</option>
          </select>
        </label>
      </div>
      <UsersTable users={users.filter((e) => role ? e.role === role : e)} />
      {/* {users.map((e) => (
        <Link className={styles.user}>
          <img src={"https://greencamel.zyberapi.site/uploads/" + e.profile} alt="" />
          <p>{e.name}</p>
          <p>{e.role}</p>
          <p>{e.isBan ? 'Энэ хэрэглэгч хориулсан' : ''}</p>
          <FaBan
            onClick={() => banUser({ banUser: e._id })}
            size={30} color='red' />
          <Link to={'/feedback/' + e._id}><FaAngleRight size={30} /></Link>
        </Link>
      ))} */}
    </div>
  )
}

export default Users
