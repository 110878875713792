import React, { useEffect, useState } from 'react'
import styles from './Feedbacks.module.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { MdDeleteForever } from "react-icons/md";

const Feedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    // const [deleteId, setDeleteId] = useState('');

    useEffect(() => {
        if (isLoading) {
            axios.get('https://greencamel.zyberapi.site/feedback?user=' + id)
                .then((e) => setFeedbacks(e.data.data))
                .catch((e) => console.log(e));
            setIsLoading(false);
        }
    })

    const deleteFeedBack = ({ deleteId }) => {
        setIsLoading(true);
        axios.delete('https://greencamel.zyberapi.site/feedback/' + deleteId)
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));
    }

    return (
        <> {isLoading === false ?
            <div className='container'>
                {feedbacks.map((e) => (
                    <div className={styles.feedback}>
                        <p className={styles.comment}>- {e.name}</p>
                        <MdDeleteForever
                            onClick={() => deleteFeedBack({ deleteId: e._id })}
                            size={25} color='red' />
                    </div>
                ))}
            </div> : <div className="loader-container"><div className="loader"></div></div>
        } </>
    )
}

export default Feedbacks
