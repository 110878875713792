import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Lands from './Pages/Lands/Lands';
import Plan from './Pages/Plan/Plan';
import Users from './Pages/Users/Users';
import Sidebar from './Components/SideBar/Sidebar';
import Feedbacks from './Pages/Users/Feedbacks/Feedbacks';
import Dashboard from './Pages/Dashboard/Dashboard';

function App() {
  return (
    <>
      <Router>
        <Sidebar />
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/land' element={<Lands />} />
          <Route path='/plan' element={<Plan />} />
          <Route path='/users' element={<Users />} />
          <Route path='/feedback/:id' element={<Feedbacks />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
