import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const EditLandsModal = ({ closeModal, plans, editId, land }) => {
    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [title, setTitle] = useState(land.title);
    const [location, setLocation] = useState(land.location);
    const [content, setContent] = useState(land.content);
    const [file, setFile] = useState('');
    // const [file2, setFile2] = useState('');

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const postLands = (ev) => {
        ev.preventDefault();

        const data = new FormData();

        data.append('title', title);
        data.append('location', location);
        data.append('file', file);
        // data.append('file2', file2);
        data.append('content', content);
        // data.append('plans', personName);
        const formattedPlans = personName.map(plan => ({ price: plan }));
        data.append('plans', JSON.stringify(formattedPlans));

        axios.put('https://greencamel.zyberapi.site/category/id/' + editId,
            data)
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Add Land</h2>
                    <h2 onClick={closeModal}>X</h2>
                </div>
                <label htmlFor="">
                    title:
                    <input value={title}
                        onChange={(e) => setTitle(e.target.value)} type="text" name="" id="" />
                </label>
                <label htmlFor="">
                    location:
                    <input value={location}
                        onChange={(e) => setLocation(e.target.value)} type="text" name="" id="" />
                </label>
                <label htmlFor="">
                    content:
                    <textarea value={content} onChange={(e) => setContent(e.target.value)} name="" id="" cols="30" rows="10"></textarea>
                </label>
                {/* <label htmlFor="">
          Second Image:
          <input onChange={(e) => setFile2(e.target.files[0])} type="file" name="" id="" />
        </label> */}
                <label htmlFor="">
                    Image:
                    <input onChange={(e) => setFile(e.target.files[0])} type="file" name="" id="" />
                </label>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Plans</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {plans.map((e) => (
                            <MenuItem
                                key={e._id}
                                value={e.price}
                                style={getStyles(e.price, personName, theme)}
                            >
                                {e.price}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="two-line">
                    <button onClick={postLands} className='button'>Save</button>
                    <button onClick={closeModal} className='cancel-button'>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default EditLandsModal
