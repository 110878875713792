import React, { useState } from 'react'
import styles from './Sidebar.module.css'
import { Link } from 'react-router-dom'
import { CiMenuBurger } from "react-icons/ci";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen || window.screen.width > 600 ?
        <div className={styles.container}>
          <div className="two-line">
            <h2 onClick={() => setIsOpen(false)}>X</h2>
            <p></p>
          </div>
          <Link to={'/'} className={styles.link}>Dashboard</Link>
          <Link to={'/land'} className={styles.link}>Lands</Link>
          <Link to={'/plan'} className={styles.link}>Plans</Link>
          <Link to={'/users'} className={styles.link}>Users</Link>
        </div> : <CiMenuBurger style={{ margin: '15px 10px' }} size={30} color='black' onClick={() => setIsOpen(true)} />
      }
    </>
  )
}

export default Sidebar
