import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { BsFillChatRightTextFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

const columns = [
    { id: 'index', label: 'No.', align: 'left' },
    {
        id: 'img',
        label: 'Нүүр зураг',
        minWidth: 50,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    { id: 'name', label: 'Нэр', minWidth: 100, align: 'center' },
    {
        id: 'role',
        label: 'Төрөл',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Үйлдэл',
        minWidth: 170,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
];

export default function UsersTable({ users }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper style={{ width: '85vw' }}>
            <TableContainer sx={{ maxHeight: '100vh' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#d3d3d3' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow className='row-item'>
                                        <TableCell align='left'>{index + 1}</TableCell>
                                        <TableCell align='center'>
                                            <img className='profile'
                                                src={'https://greencamel.zyberapi.site/uploads/' + row.profile} alt="" />
                                        </TableCell>
                                        <TableCell align='center'>{row.name}</TableCell>
                                        <TableCell align='center'>{row.role}</TableCell>
                                        <TableCell align='center'>
                                            <Link to={'/feedback/' + row._id}>
                                                <BsFillChatRightTextFill size={25} color='green' />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}