import React, { useEffect, useState } from 'react'
import styles from './Plan.module.css'
import PlanModal from '../../Modal/PlanModal'
import axios from 'axios';
import DocViewer from "react-doc-viewer";
import PlanTable from '../../Tables/PlanTable';

const Plan = () => {
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const closeModal = () => {
    setIsModal(false);
  }

  useEffect(() => {
    if (isLoading) {
      axios.get('https://greencamel.zyberapi.site/plan')
        .then((response) => {
          setPlans(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => console.log(error))
    }
    document.title = 'Төлөвлөгөө';
  }, [isLoading]); // Adding [isLoading] as dependency

  return (
    <>
      {isModal ? <PlanModal closeModal={closeModal} /> : null}
      <div className='container'>
        <button onClick={() => setIsModal(true)} className='button'>Add Plan</button>
        <div className="grid-4">
          <PlanTable plans={plans} />
          {/* {plans.map((plan) => (
            <div key={plan.id} className={styles.card}>
              <div className={styles.card_header}>
                <h2>{plan.price}$</h2>
                <p>/{plan.days} days</p>
              </div>
              <div className={styles.content}>
                <p>{plan.name}</p>
                <a href={'https://greencamel.zyberapi.site/uploads/' + plan.docx}>download plan</a>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  )
}

export default Plan;
